<template>
  <div>

    <CRow>
      <CCol>
        <span class="font-weight-bold"> {{ onCheck(fields.preparation) }} </span>
      </CCol>
    </CRow>

    <CRow class="mt-2">
      <CCol>
        <table-consistency
          small
          :items="data.preparation"
          :fields="onCheck(table.preparation)"
        />
      </CCol>
    </CRow>

    <CRow>
      <CCol col="12" class="text-right">
        <CButton size="sm" color="success" shape="pill" variant="outline" @click="onAddPlan">
          <CIcon name="cil-plus"/>
        </CButton>
      </CCol>
    </CRow>


    <CRow>
      <CCol>
        <span class="font-weight-bold"> {{ onCheck(fields.students) }} </span>
      </CCol>
    </CRow>

    <CRow class="mt-2">
      <CCol>
        <table-consistency
          small
          :items="data.students"
          :fields="onCheck(table.students)"
        />
      </CCol>
    </CRow>

    <CRow>
      <CCol>
        <span class="font-weight-bold"> {{ onCheck(fields.advisors) }} </span>
      </CCol>
    </CRow>

    <CRow class="mt-2">
      <CCol>
        <table-consistency
          small
          :items="data.advisors"
          :fields="onCheck(table.advisors)"
        />
      </CCol>
    </CRow>

    <CRow>
      <CCol>
        <span class="font-weight-bold"> {{ onCheck(fields.mentors) }} </span>
      </CCol>
    </CRow>

    <CRow class="mt-2">
      <CCol>
        <table-consistency
          small
          :items="data.mentors"
          :fields="onCheck(table.mentors)"
        />
      </CCol>
    </CRow>


    <CRow>
      <CCol>
        <span class="font-weight-bold"> {{ onCheck(fields.riskManagement) }} </span>
      </CCol>
    </CRow>

    <CRow class="mt-2">
      <CCol>
        <table-consistency
          small
          :items="data.riskManagement"
          :fields="onCheck(table.riskManagement)"
        />
      </CCol>
    </CRow>

    <CRow>
      <CCol>
        <span class="font-weight-bold"> {{ onCheck(fields.lessonPlan) }} </span>
      </CCol>
    </CRow>

    <CRow class="mt-2">
      <CCol>
        <table-consistency
          small
          :items="data.lessonPlan"
          :fields="onCheck(table.lessonPlan)"
        />
      </CCol>
    </CRow>

    <CRow>
      <CCol>
        <span class="font-weight-bold"> {{ onCheck(fields.activities) }} </span>
      </CCol>
    </CRow>

    <CRow class="mt-2">
      <CCol>
        <table-consistency
          small
          :items="data.activities"
          :fields="onCheck(table.activities)"
        />
      </CCol>
    </CRow>

    <CRow>
      <CCol>
        <span class="font-weight-bold"> {{ onCheck(fields.studentsReport) }} </span>
      </CCol>
    </CRow>

    <CRow class="mt-2">
      <CCol>
        <table-consistency
          small
          :items="data.studentsReport"
          :fields="onCheck(table.studentsReport)"
        />
      </CCol>
    </CRow>

    <CRow>
      <CCol>
        <span class="font-weight-bold"> {{ onCheck(fields.studentsMonitoring) }} </span>
      </CCol>
    </CRow>

    <CRow class="mt-2">
      <CCol>
        <table-consistency
          small
          :items="data.studentsMonitoring"
          :fields="onCheck(table.studentsMonitoring)"
        />
      </CCol>
    </CRow>

    <CRow>
      <CCol>
        <span class="font-weight-bold"> {{ onCheck(fields.internshipMentors) }} </span>
      </CCol>
    </CRow>

    <CRow class="mt-2">
      <CCol>
        <table-consistency
          small
          :items="data.internshipMentors"
          :fields="onCheck(table.internshipMentors)"
        />
      </CCol>
    </CRow>

    <CRow>
      <CCol>
        <span class="font-weight-bold"> {{ onCheck(fields.experienceAdvisors) }} </span>
      </CCol>
    </CRow>

    <CRow class="mt-2">
      <CCol>
        <table-consistency
          small
          :items="data.experienceAdvisors"
          :fields="onCheck(table.experienceAdvisors)"
        />
      </CCol>
    </CRow>




  </div>
</template>

<script>

import {mapGetters} from 'vuex'

import Vue from 'vue'
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import TableConsistency from "@/project/containers/sub/TableConsistency";

Vue.use(VueQuillEditor)

export default {
  name: 'Consistency',
  components: {TableConsistency},
  props: {
    disable: {
      type: Boolean,
      default: false
    },
    tqf: {
      type: Number,
      default: 0
    },
  },
  data: function () {
    return {
      table: {

        preparation : [{
          key: "th",
          value: [
            {key: 'preparation', label: 'แผนการเตรียมสถานที่ฝึก',_style:'text-align: center'},
            {key: 'specify', label: 'การเตรียมสถานที่ฝึก (ระบุกรณีมีความต่างจากแผน)',_style:'text-align: center'},
            {key: 'adjustment', label: 'ข้อคิดเห็นเพื่อการวางแผนในอนาคต',_style:'text-align: center'},
            {key: '#', label: '#',_style:'text-align: center'},
          ]
        }, {
          key: "en",
          value: [
            {key: 'preparation', label: 'Training or Co-operative Facilities Preparation Plan',_style:'text-align: center'},
            {key: 'specify', label: 'Training or Co-operative Facilities Preparation (specify preparation that deviates from plan)',_style:'text-align: center'},
            {key: 'adjustment', label: 'Recommendations for Future Adjustment',_style:'text-align: center'},
            {key: '#', label: '#',_style:'text-align: center'},
          ]
        }],
        students : [{
          key: "th",
          value: [
            {key: 'preparation', label: 'แผนการเตรียมนักศึกษา',_style:'text-align: center'},
            {key: 'specify', label: 'การเตรียมนักศึกษา (ระบุกรณีมีความต่างจากแผน)',_style:'text-align: center'},
            {key: 'adjustment', label: 'ข้อคิดเห็นเพื่อการวางแผนในอนาคต',_style:'text-align: center'},
            {key: '#', label: '#',_style:'text-align: center'},
          ]
        }, {
          key: "en",
          value: [
            {key: 'preparation', label: 'Student Orientation Plan',_style:'text-align: center'},
            {key: 'specify', label: 'Student Orientation (specify orientation that deviates from plan)',_style:'text-align: center'},
            {key: 'adjustment', label: 'Recommendations for Future Adjustment',_style:'text-align: center'},
            {key: '#', label: '#',_style:'text-align: center'},
          ]
        }],
        advisors : [{
          key: "th",
          value: [
            {key: 'preparation', label: 'แผนการเตรียมอาจารย์ที่ปรึกษา/อาจารย์นิเทศ',_style:'text-align: center'},
            {key: 'specify', label: 'การเตรียมอาจารย์ที่ปรึกษา/อาจารย์นิเทศ (ระบุกรณีมีความต่างจากแผน)',_style:'text-align: center'},
            {key: 'adjustment', label: 'ข้อคิดเห็นเพื่อการวางแผนในอนาคต',_style:'text-align: center'},
            {key: '#', label: '#',_style:'text-align: center'},
          ]
        }, {
          key: "en",
          value: [
            {key: 'preparation', label: 'Internship Advisors/ Monitors Orientation Plan',_style:'text-align: center'},
            {key: 'specify', label: 'Internship Advisors/ Monitors Orientation (specify orientation that deviates from plan)',_style:'text-align: center'},
            {key: 'adjustment', label: 'Recommendations for Future Adjustment',_style:'text-align: center'},
            {key: '#', label: '#',_style:'text-align: center'},
          ]
        }],
        mentors : [{
          key: "th",
          value: [
            {key: 'preparation', label: 'แผนการเตรียมพนักงานพี่เลี้ยง/อาจารย์พี่เลี้ยง',_style:'text-align: center'},
            {key: 'specify', label: 'การเตรียมพนักงานพี่เลี้ยง/อาจารย์พี่เลี้ยง (ระบุกรณีมีความต่างจากแผน)',_style:'text-align: center'},
            {key: 'adjustment', label: 'ข้อคิดเห็นเพื่อการวางแผนในอนาคต',_style:'text-align: center'},
            {key: '#', label: '#',_style:'text-align: center'},
          ]
        }, {
          key: "en",
          value: [
            {key: 'preparation', label: 'Internship Mentors Orientation Plan',_style:'text-align: center'},
            {key: 'specify', label: 'Internship Mentors Orientation (specify orientation that deviates from plan)',_style:'text-align: center'},
            {key: 'adjustment', label: 'Recommendations for Future Adjustment',_style:'text-align: center'},
            {key: '#', label: '#',_style:'text-align: center'},
          ]
        }],
        riskManagement : [{
          key: "th",
          value: [
            {key: 'preparation', label: 'แผนการเตรียมเรื่องการจัดการความเสี่ยง',_style:'text-align: center'},
            {key: 'specify', label: 'การเตรียมการจัดการความเสี่ยง (ระบุกรณีมีความต่างจากแผน)',_style:'text-align: center'},
            {key: 'adjustment', label: 'ข้อคิดเห็นเพื่อการวางแผนในอนาคต',_style:'text-align: center'},
            {key: '#', label: '#',_style:'text-align: center'},
          ]
        }, {
          key: "en",
          value: [
            {key: 'preparation', label: 'Risk Management Plan',_style:'text-align: center'},
            {key: 'specify', label: 'Risk Management (specify preparation that deviates from plan)',_style:'text-align: center'},
            {key: 'adjustment', label: 'Recommendations for Future Adjustment',_style:'text-align: center'},
            {key: '#', label: '#',_style:'text-align: center'},
          ]
        }],
        lessonPlan : [{
          key: "th",
          value: [
            {key: 'preparation', label: 'แผนการเตรียมแผนการสอน',_style:'text-align: center'},
            {key: 'specify', label: 'การเตรียมแผนการสอน (ระบุกรณีมีความต่างจากแผน)',_style:'text-align: center'},
            {key: 'adjustment', label: 'ข้อคิดเห็นเพื่อการวางแผนในอนาคต',_style:'text-align: center'},
            {key: '#', label: '#',_style:'text-align: center'},
          ]
        }, {
          key: "en",
          value: [
            {key: 'preparation', label: 'Lesson Plan',_style:'text-align: center'},
            {key: 'specify', label: 'Lesson Plan (specify preparation that deviates from plan)',_style:'text-align: center'},
            {key: 'adjustment', label: 'Recommendations for Future Adjustment',_style:'text-align: center'},
            {key: '#', label: '#',_style:'text-align: center'},
          ]
        }],
        activities : [{
          key: "th",
          value: [
            {key: 'preparation', label: 'แผนการเตรียมกิจกรรมหลัก',_style:'text-align: center'},
            {key: 'specify', label: 'การเตรียมกิจกรรมหลัก (ระบุกรณีมีความต่างจากแผน)',_style:'text-align: center'},
            {key: 'adjustment', label: 'ข้อคิดเห็นเพื่อการวางแผนในอนาคต',_style:'text-align: center'},
            {key: '#', label: '#',_style:'text-align: center'},
          ]
        }, {
          key: "en",
          value: [
            {key: 'preparation', label: 'Student Activity Plan',_style:'text-align: center'},
            {key: 'specify', label: 'Student Activity Plan (specify preparation that deviates from plan)',_style:'text-align: center'},
            {key: 'adjustment', label: 'Recommendations for Future Adjustment',_style:'text-align: center'},
            {key: '#', label: '#',_style:'text-align: center'},
          ]
        }],
        studentsReport : [{
          key: "th",
          value: [
            {key: 'preparation', label: 'แผนการเตรียมรายงาน หรืองานที่นักศึกษาได้รับมอบหมาย',_style:'text-align: center'},
            {key: 'specify', label: 'การเตรียมรายงาน หรืองานที่นักศึกษาได้รับมอบหมาย (ระบุกรณีมีความต่างจากแผน)',_style:'text-align: center'},
            {key: 'adjustment', label: 'ข้อคิดเห็นเพื่อการวางแผนในอนาคต',_style:'text-align: center'},
            {key: '#', label: '#',_style:'text-align: center'},
          ]
        }, {
          key: "en",
          value: [
            {key: 'preparation', label: 'Student’s Report/Assignment Plan',_style:'text-align: center'},
            {key: 'specify', label: 'Student’s Report/Assignment (specify preparation that deviates from plan)',_style:'text-align: center'},
            {key: 'adjustment', label: 'Recommendations for Future Adjustment',_style:'text-align: center'},
            {key: '#', label: '#',_style:'text-align: center'},
          ]
        }],
        studentsMonitoring : [{
          key: "th",
          value: [
            {key: 'preparation', label: 'แผนการเตรียมรายงาน หรืองานที่นักศึกษาได้รับมอบหมาย',_style:'text-align: center'},
            {key: 'specify', label: 'การเตรียมรายงาน หรืองานที่นักศึกษาได้รับมอบหมาย (ระบุกรณีมีความต่างจากแผน)',_style:'text-align: center'},
            {key: 'adjustment', label: 'ข้อคิดเห็นเพื่อการวางแผนในอนาคต',_style:'text-align: center'},
            {key: '#', label: '#',_style:'text-align: center'},
          ]
        }, {
          key: "en",
          value: [
            {key: 'preparation', label: 'Student’s Monitoring Plan',_style:'text-align: center'},
            {key: 'specify', label: 'Student’s Monitoring Plan (specify preparation that deviates from plan)',_style:'text-align: center'},
            {key: 'adjustment', label: 'Recommendations for Future Adjustment',_style:'text-align: center'},
            {key: '#', label: '#',_style:'text-align: center'},
          ]
        }],
        internshipMentors : [{
          key: "th",
          value: [
            {key: 'preparation', label: 'แผนการเตรียมหน้าที่และความรับผิดชอบของพนักงานพี่เลี้ยง/อาจารย์พี่เลี้ยง',_style:'text-align: center'},
            {key: 'specify', label: 'การการเตรียมหน้าที่และความรับผิดชอบของพนักงานพี่เลี้ยง/อาจารย์พี่เลี้ยง (ระบุกรณีมีความต่างจากแผน)',_style:'text-align: center'},
            {key: 'adjustment', label: 'ข้อคิดเห็นเพื่อการวางแผนในอนาคต',_style:'text-align: center'},
            {key: '#', label: '#',_style:'text-align: center'},
          ]
        }, {
          key: "en",
          value: [
            {key: 'preparation', label: 'Duties and Responsibilities of Internship Mentors Plan',_style:'text-align: center'},
            {key: 'specify', label: 'Duties and Responsibilities of Internship Mentors (specify preparation that deviates from plan)',_style:'text-align: center'},
            {key: 'adjustment', label: 'Recommendations for Future Adjustment',_style:'text-align: center'},
            {key: '#', label: '#',_style:'text-align: center'},
          ]
        }],
        experienceAdvisors : [{
          key: "th",
          value: [
            {key: 'preparation', label: 'แผนการเตรียมหน้าที่และความรับผิดชอบของอาจารย์ที่ปรึกษา/อาจารย์นิเทศ',_style:'text-align: center'},
            {key: 'specify', label: 'การเตรียมหน้าที่และความรับผิดชอบของอาจารย์ที่ปรึกษา/อาจารย์นิเทศ (ระบุกรณีมีความต่างจากแผน)',_style:'text-align: center'},
            {key: 'adjustment', label: 'ข้อคิดเห็นเพื่อการวางแผนในอนาคต',_style:'text-align: center'},
            {key: '#', label: '#',_style:'text-align: center'},
          ]
        }, {
          key: "en",
          value: [
            {key: 'preparation', label: 'Duties and Responsibilities of Field Experience Advisors/Monitors Plan',_style:'text-align: center'},
            {key: 'specify', label: 'Duties and Responsibilities of Field Experience Advisors/Monitors (specify preparation that deviates from plan)',_style:'text-align: center'},
            {key: 'adjustment', label: 'Recommendations for Future Adjustment',_style:'text-align: center'},
            {key: '#', label: '#',_style:'text-align: center'},
          ]
        }],

        grade: [{
          key: "th",
          value: [
            {key: 'lerning', label: 'ผลลัพธ์การเรียนรู้',_style:'text-align: center'},
            {key: 'actions', label: 'ประสิทธิผล',_style:'text-align: center'},
            {key: 'teaching', label: 'วิธีการจัดการเรียนการสอน',_style:'text-align: center'},
            // {key: 'teachingSuggestion', label: 'ข้อเสนอแนะ (ถ้ามี)',_style:'text-align: center'},
            {key: 'achievement', label: 'วิธีประเมินผล',_style:'text-align: center'},
            // {key: 'achievementSuggestion', label: 'ข้อเสนอแนะ (ถ้ามี)',_style:'text-align: center'}
            {key: '#', label: '#',_style:'text-align: center'},
          ]
        }, {
          key: "en",
          value: [
            {key: 'lerning', label: 'Lerning Outcome',_style:'text-align: center'},
            {key: 'actions', label: 'Lerning Achievement',_style:'text-align: center'},
            {key: 'teaching', label: 'Teaching Methodology',_style:'text-align: center'},
            // {key: 'teachingSuggestion', label: 'Suggestion',_style:'text-align: center'},
            {key: 'achievement', label: 'Achievement Methodology',_style:'text-align: center'},
            // {key: 'achievementSuggestion', label: 'จำนวน',_style:'text-align: center'}
            {key: '#', label: '#',_style:'text-align: center'},
          ]
        }],

      },
      fields : {
        preparation: [
          {
            key: "th",
            value: "1. การกำหนด / การเตรียมสถานที่ฝึก (รวมสิ่งอำนวยความสะดวก และการสนับสนุนที่ต้องการ จากสถานที่ฝึก) "
          },
          {
            key: "en",
            value: "1. Selection / Preparation of Training or Co-operative Facilities (including supporting facilities at co-operative workplace) "
          }
        ],
        students: [
          {
            key: "th",
            value: "2. การเตรียมนักศึกษา (การประชุมหรืออบรมนักศึกษา ก่อนออกภาคสนาม)"
          },
          {
            key: "en",
            value: "2. Orientation of Students (providing meetings or trainings before field experience/ training/ " +
              "co-operative study)"
          }
        ],
        advisors: [
          {
            key: "th",
            value: "3. การเตรียมอาจารย์ที่ปรึกษา/อาจารย์นิเทศ"
          },
          {
            key: "en",
            value: "3. Orientation of Internship Advisors/ Monitors"
          }
        ],
        mentors: [
          {
            key: "th",
            value: "4. การเตรียมพนักงานพี่เลี้ยง/อาจารย์พี่เลี้ยง ในสถานที่ฝึก (ถ้ามี)"
          },
          {
            key: "en",
            value: "4. Orientation of Internship Mentors in Training/ Co-operative Workplace (if required)"
          }
        ],
        riskManagement: [
          {
            key: "th",
            value: "5. การเตรียมเรื่องการจัดการความเสี่ยง เช่น อุบัติเหตุ หรือความเสียหายที่เกิดจากการทำงาน"
          },
          {
            key: "en",
            value: "5. Risk Management (such as accidents or damage caused by student trainee)"
          }
        ],
        lessonPlan: [
          {
            key: "th",
            value: "6. การเตรียมแผนการสอน ระบุหัวข้อ/รายละเอียด สัปดาห์ที่สอน จำนวนชั่วโมงการสอน กิจกรรมการเรียนการสอนและสื่อที่ใช้ รวมทั้งอาจารย์ผู้สอนในแต่ละหัวข้อ/รายละเอียดของรายวิชา"
          },
          {
            key: "en",
            value: "6. Lesson Plan (specify topics, week number, number of lecture hours, activity and material and instructor)"
          }
        ],
        activities: [
          {
            key: "th",
            value: "7. กิจกรรมหลักที่นักศึกษาต้องผ่านในการฝึกประสบการณ์ภาคสนาม"
          },
          {
            key: "en",
            value: "7. Activities that the Students Are Expected to Complete"
          }
        ],
        studentsReport: [
          {
            key: "th",
            value: "8. รายงานหรืองานที่นักศึกษาได้รับมอบหมาย"
          },
          {
            key: "en",
            value: "8. Student’s Report/Assignment"
          }
        ],
        studentsMonitoring: [
          {
            key: "th",
            value: "9. การเตรียมการติดตามผลการเรียนรู้ การฝึกประสบการณ์ภาคสนามของนักศึกษา"
          },
          {
            key: "en",
            value: "9. Student’s Monitoring in Field Experience/Training/Co-operative Study"
          }
        ],
        internshipMentors: [
          {
            key: "th",
            value: "10. หน้าที่และความรับผิดชอบของพนักงานพี่เลี้ยง/อาจารย์พี่เลี้ยง"
          },
          {
            key: "en",
            value: "10. Duties and Responsibilities of Internship Mentors"
          }
        ],
        experienceAdvisors: [
          {
            key: "th",
            value: "11. หหน้าที่และความรับผิดชอบของอาจารย์ที่ปรึกษา/อาจารย์นิเทศ"
          },
          {
            key: "en",
            value: "11. Duties and Responsibilities of Field Experience Advisors/Monitors"
          }
        ],

      },
      data: {
        preparation : [],
        grade:[
          {
            lerning:"A",
            actions:"Achieved",
            teaching:"",
            achievement:""
          }
        ],
      }

    }
  },
  mounted() {

  },

  created() {
    // this.content = this.onCheck(this.values.fields)
  },

  beforeDestroy() {

  },

  methods: {

    onCheck(key) {
      for (var i = 0; i < key.length; i++) {
        if (key[i].key == this.lang) {
          return key[i].value;
        }
      }
    },

    chaneSuggestion(html, text, quill) {
      // this.onCheckIndex(this.data.reason,html.html)
    },

    chaneLecturer(html, text, quill) {
      // this.onCheckIndex(this.data.reason,html.html)
    },

    addVerlification(){

    },

  },



  computed: {
    ...mapGetters({
      lang: "setting/objLang",
    })
  },

  watch: {
    // Watch content change

  }
}
</script>
