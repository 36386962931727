<template>
  <div>
    <CRow>
      <CCol sm="12" md="4">

        <CRow>
          <CCol col="4">
            <p class="mt-2 font-weight-bold">1. {{ $t('courseCode') }} :</p>
          </CCol>
          <CCol col="8">
            <multiselect
              :search="true"
              :disabled="!disable"
              v-model="setUp"
              :options="courses"
              label="code"
              track-by="code"
              searchPlaceholder="..."
              selectionType="text"
            >
              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title">{{ props.option.code }}</span>
                </div>
              </template>
            </multiselect>
          </CCol>
        </CRow>
      </CCol>
      <CCol sm="12" md="6">
        <p class="mt-2"><span class="font-weight-bold">{{ $t('courseTitle') }} : </span>
          {{ onCheck(setUp.name) }}
        </p>
      </CCol>
      <CCol sm="12" md="2">
        <p class="mt-2"><span class="font-weight-bold">{{ $t('credit') }} : </span>
          {{ setUp.credit }}
        </p>
      </CCol>
    </CRow>

    <p class="mt-2 mb-3 font-weight-bold ">2. {{ $t('programType') }}</p>
    <CRow class="mb-4">
      <CCol>
        <p class=" mb-2">{{ $t('typeProgram') }}</p>
        <p class=" mb-3 ml-2 ">{{ onCheck(setUp.type) }}</p>

        <CMultiSelect
          v-if="reserve.statePrograms"
          @update="onCheckTypePrograms"
          :options="defualts.programs"
          :search="false"
          :selected="data.programs"
          :selection="false"
          inline
          optionsEmptyPlaceholder="No options placeholder"
          searchPlaceholder="Search"
          selectionType="tags"
        />
      </CCol>
      <CCol>
        <p class=" mb-3 ">{{ $t('typeCourse') }}</p>
        <p class=" mb-3 ml-2 ">{{ onCheck(setUp) }}</p>
        <CMultiSelect
          v-if="reserve.stateCategorys"
          @update="onCheckTypeCategorys"
          :options="defualts.categorys"
          :search="false"
          :selected="data.categorys"
          :selection="false"
          inline
          optionsEmptyPlaceholder="No options placeholder"
          searchPlaceholder="Search"
          selectionType="tags"
        />
      </CCol>
    </CRow>


    <p class="mt-2 mb-3 font-weight-bold">3. {{ $t('instructor') }} </p>
    <CRow>
      <CCol>
        <table-list
          :caption="$t('instructor')"
          :items="data.instructor"
          :fields=" $t('tableInstructor')"
          @instructor="onChangeInstructor"
          @codication="onChangeCodication"
        />
      </CCol>

    </CRow>

    <dialog-instructors/>

    <!--    // Add ethicsAndMoralitys-->
    <CRow>
      <CCol col="12" class="text-right">
        <CButton size="sm" color="success" shape="pill" variant="outline" @click="addInstructor">
          <CIcon name="cil-plus"/>
        </CButton>
      </CCol>
    </CRow>
    <!--    // end Add ethicsAndMoralitys-->


    <!--    // view academicYears-->
    <p class="mt-2 mb-3 font-weight-bold">4. {{ $t('semeterStudent') }} </p>
    <CRow class="ml-2" v-for="(item, index) in data.academicYears" v-bind:key="index">
      <CCol sm="12" md="4">
        <CInput type="number"
                :disabled="true"
                :horizontal="{label: 'col-sm-5 font-weight-bold', input: 'col-sm-7'}"
                :label="$t('academicYear')"
                v-model="item.academicYear"/>
      </CCol>
      <CCol sm="12" md="4">

        <CSelect :label="$t('semester')"
                 :disabled="true"
                 :horizontal="{label: 'col-sm-5 font-weight-bold', input: 'col-sm-7'}"
                 :value="item.semester"
                 :options="$t('defualt.semester')"/>
      </CCol>
      <CCol sm="12" md="3">

        <CSelect :label="$t('yearStudy')"
                 :disabled="true"
                 :horizontal="{label: 'col-sm-5 font-weight-bold', input: 'col-sm-7'}"
                 :value="item.yearStudy"
                 :options="$t('defualt.yearStudy')"/>
      </CCol>
      <CCol sm="12" md="1" class="text-right">
        <CButton size="sm" color="danger" shape="pill" variant="outline" @click="onRemmoveSemester(item)">
          <CIcon name="cil-minus"/>
        </CButton>
      </CCol>
    </CRow>
    <!--    // end view academicYears-->

    <!--    // add academicYears-->
    <CRow v-if="!edit" class="ml-2">
      <CCol sm="12" md="4">
        <CInput type="number" :horizontal="{label: 'col-sm-5 font-weight-bold', input: 'col-sm-7'}"
                :label="$t('academicYear')"
                v-model="reserve.academicYears.academicYear"/>
      </CCol>
      <CCol sm="12" md="4">
        <CSelect :label="$t('semester')"
                 :horizontal="{label: 'col-sm-5 font-weight-bold', input: 'col-sm-7'}"
                 @update:value="onSelectSemester"
                 :options="$t('defualt.semester')"/>
      </CCol>
      <CCol sm="12" md="3">
        <CSelect :label="$t('semester')"
                 :horizontal="{label: 'col-sm-5 font-weight-bold', input: 'col-sm-7'}"
                 @update:value="onSelectYearStudy"
                 :options="$t('defualt.yearStudy')"/>
      </CCol>

      <CCol sm="12" md="1" class="text-right">
        <CButton size="sm" color="success" shape="pill" variant="outline" @click="onAddSemester">
          <CIcon name="cil-plus"/>
        </CButton>
      </CCol>
    </CRow>
    <!--    // end add academicYears-->

    <!--    // view preRequisite-->
    <p class="mt-2 mb-3 font-weight-bold">5. {{ $t('preRequisite') }} </p>
    <CRow class="pl-4 mb-2" v-for="(item, index) in setUpPreRequest" v-bind:key="index">
      <CCol col="11">
        <CRow>
          <CCol col="2">
            <p class="mt-2 font-weight-bold">{{ $t('courseCode') }} :</p>
          </CCol>
          <CCol col="4">
            <p class="mt-2 font-weight-bold"> {{ item.code }}</p>
          </CCol>
          <CCol col="6">
            <p class="mt-2 font-weight-bold">
              {{ $t('courseTitle') }} : {{ onCheck(item.name) }}
            </p>
          </CCol>
        </CRow>
      </CCol>
<!--      <CCol col="1" class="text-right">-->
<!--        <CButton size="sm" color="danger" shape="pill" variant="outline" @click="onRemmovePreRequisite(item)">-->
<!--          <CIcon name="cil-minus"/>-->
<!--        </CButton>-->
<!--      </CCol>-->
    </CRow>
    <!--    // end view preRequisite-->

    <!--    // Add preRequisite-->
    <CRow v-if="edit"  class="pl-4">
      <CCol col="11">
        <CRow>
          <CCol col="2">
            <p class="mt-2 font-weight-bold">{{ $t('courseCode') }} :</p>
          </CCol>
          <CCol col="4">
            <multiselect
              v-model="reserve.preRequisite"
              :options="defualts.courses"
              label="label"
              track-by="label"
            >
              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title">{{ props.option.label }} {{ props.option.name }} {{ props.option.credit }}</span>
                </div>
              </template>
            </multiselect>
          </CCol>
          <CCol col="6">
            <p class="mt-2 font-weight-bold">
              {{ $t('courseTitle') }} : {{ reserve.preRequisite.name }}
            </p>
          </CCol>
        </CRow>
      </CCol>
      <CCol col="1" class="text-right">
        <CButton size="sm" color="success" shape="pill" variant="outline" @click="onAddPreRequisite">
          <CIcon name="cil-plus"/>
        </CButton>
      </CCol>
    </CRow>
    <!--    // end Add preRequisite-->

    <!--    // view coRequisite-->
    <p class="mt-2 mb-3 font-weight-bold">6. {{ $t('coRequisite') }} </p>
    <CRow class="pl-4 mb-2" v-for="(item, index) in setUpStudyRequest" v-bind:key="index">
      <CCol col="11">
        <CRow>
          <CCol col="2">
            <p class="mt-2 font-weight-bold">{{ $t('courseCode') }} :</p>
          </CCol>
          <CCol col="4">
            <p class="mt-2 font-weight-bold"> {{ item.code }}</p>
          </CCol>
          <CCol col="6">
            <p class="mt-2 font-weight-bold">
              {{ $t('courseTitle') }} : {{ onCheck(item.name) }}
            </p>
          </CCol>
        </CRow>
      </CCol>
<!--      <CCol col="1" class="text-right">-->
<!--        <CButton size="sm" color="danger" shape="pill" variant="outline" @click="onRemmoveCoRequisite(item)">-->
<!--          <CIcon name="cil-minus"/>-->
<!--        </CButton>-->
<!--      </CCol>-->
    </CRow>
    <!--    // view coRequisite-->

    <!--    // Add coRequisite-->
    <CRow v-if="edit"  class="pl-4">
      <CCol col="11">
        <CRow>
          <CCol col="2">
            <p class="mt-2 font-weight-bold">{{ $t('courseCode') }} :</p>
          </CCol>
          <CCol col="4">
            <multiselect
              v-model="reserve.coRequisite"
              :options="defualts.courses"
              label="label"
              track-by="label"
            >
              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title">{{ props.option.label }} {{ props.option.name }} {{ props.option.credit }}</span>
                </div>
              </template>
            </multiselect>
          </CCol>
          <CCol col="6">
            <p class="mt-2 font-weight-bold">
              {{ $t('courseTitle') }} : {{ reserve.coRequisite.name }}
            </p>
          </CCol>
        </CRow>
      </CCol>
      <CCol col="1" class="text-right">
        <CButton size="sm" color="success" shape="pill" variant="outline" @click="onAddCoRequisite">
          <CIcon name="cil-plus"/>
        </CButton>
      </CCol>
    </CRow>
    <!--    // end Add coRequisite-->

  </div>
</template>

<script>

import {mapGetters} from 'vuex'

import Multiselect from 'vue-multiselect'
import 'vue-select/dist/vue-select.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import TableList from "@/project/containers/sub/TableList";
import DialogInstructors from "@/project/views/pages/dialog/DialogInstructors";

export default {
  name: 'GenerallnfimationSub',
  components: {DialogInstructors, TableList, Multiselect},
  props: {
    disable: {
      type: Boolean,
      default: false
    },
    edit: {
      type: Boolean,
      default: false
    },
    tqf: {
      type: Number,
      default: 0
    },
  },
  data: function () {
    return {
      darkModal: false,

      fields: {

      },

      defualts: {
        courses: [],
        programs: [],
        categorys: []
      },
      data: {
        courseinfo: {
          name:[{}]
        },
        instructor: [],
        course: [],
        programs: [],
        categorys: [],
        academicYears: [],
        preRequisite: [],
        coRequisite: [],
      },
      reserve: {
        academicYears: {
          academicYears:null,
          semester:null,
          yearStudy:null,
        },
        preRequisite: {},
        coRequisite: {},
        statePrograms: false,
        stateCategorys: false,
      }
    }
  },
  mounted() {
    // this.$store.dispatch("course/onGetCourses")

  },

  created() {

    // this.content = this.onCheck(this.values.fields)
  },

  beforeDestroy() {

  },

  methods: {
    onCheck(key) {
      try{

        for (var i = 0; i < key.length; i++) {
          if (key[i].key == this.lang) {
            return key[i].value;
          }
        }
      }catch (e) {

      }

    },

    onCheckTypePrograms(item) {
      // console.log("item",item)
    },

    onCheckTypeCategorys(item) {
      // console.log(item)
    },

    // open dialog add profile
    addInstructor() {
      this.$store.dispatch("dialogCreate/setDialogInstructors", true)
    },

    onRemmoveSemester(item) {
      this.data.academicYears = this.data.academicYears.filter(el => el !== item);

    },


    onRemmovePreRequisite(item) {
      this.data.preRequisite = this.data.preRequisite.filter(el => el !== item);
    },
    onAddPreRequisite() {
      var item = {}
      item.value = this.reserve.preRequisite
      this.data.preRequisite.push(item)
      this.reserve.preRequisite = {}
    },

    onRemmoveCoRequisite(item) {
      this.data.coRequisite = this.data.coRequisite.filter(el => el !== item);
    },
    onAddCoRequisite() {
      var item = {}
      item.value = this.reserve.coRequisite
      this.data.coRequisite.push(item)
      this.reserve.coRequisite = {}
    },


    onSelectFacultys(value, e) {
      this.$store.dispatch("course/onGetDepartment", value.code)
    },

    // Table Controll
    onChangeInstructor(value) {
      console.log("instructor", value)
    },
    onChangeCodication(value) {
      console.log("codication", value)
    },
    // End Table Controll



    // Check Select
    onSelectSemester(value, e) {
      this.reserve.academicYears.semester = value
    },

    onSelectYearStudy(value, e) {
      this.reserve.academicYears.yearStudy = value
    },

    onAddSemester() {
      this.data.academicYears.push(this.reserve.academicYears)
      this.reserve.academicYears = {}
    },

    //
  },


  computed: {
    ...mapGetters({
      setUp: "setUp/objSelectCours",
      setUpPreRequest: "setUp/objSelectPreRequest",
      setUpStudyRequest: "setUp/objSelectStudyRequest",



      lang: "setting/objLang",
      courses: "course/objCourses",
      instructor: "course/objInstructor",
      program: "course/objProgram",
      category: "course/objCategory",
      addInstructors: "dialogCreate/onGetInstructors",

    })
  },

  watch: {
    courses(value) {
      var itemCourse = [];
      for (var i = 0; i < value.length; i++) {
        let obj = value[i]
        var item = {};
        item.label = obj.code;
        item.name = this.onCheck(obj.name)
        item.credit = obj.credit;
        item.value = obj
        itemCourse.push(item)
      }

      this.defualts.courses = itemCourse;


      // this.data.coRequisite.push(item)
    },

    instructor(value) {

      var itemCourse = [];
      for (var i = 0; i < value.length; i++) {
        let obj = value[i]
        var item = {};

        item.userId = obj.userId;
        item.fullname = this.onCheck(obj.name);
        if (obj.type == 99) {
          item.instructor = true;
        } else {
          item.instructor = false;
        }

        if (obj.type != 99) {
          item.codication = true;
        } else {
          item.codication = false;
        }
        item.value = obj
        itemCourse.push(item)
      }
      this.data.instructor = itemCourse;
    },


    program(value) {
      this.reserve.statePrograms = false;
      this.defualts.programs = this.onCheck(value)


      var vm = this
      setTimeout(function () {
        vm.reserve.statePrograms = true;
      }, 500);

    },
    category(value) {
      this.reserve.stateCategorys = false;
      this.defualts.categorys = this.onCheck(value)

      var vm = this
      setTimeout(function () {
        vm.reserve.stateCategorys = true;
      }, 500);
    },

    'data.courseinfo'(value) {

      this.$store.dispatch("course/onGetCoursesById", value.value)
      this.$store.dispatch("course/onGetOperations", value.value)
      this.$store.dispatch("course/onGetVerifications", value.value)

    },

    addInstructors(value) {
      this.data.instructor.push(value)

    }


  }
}
</script>
