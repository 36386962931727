<template>
  <div>
    <dialog-instructors/>
    <dialog-lesson-plans/>
    <dialog-evaluation-plans/>
    <dialog-develop-plans/>
    <dialog-consistency/>
    <CRow>
      <CCol>
        <CCard>
          <CCardHeader color="primary">
            <CRow>
              <CCol class="text-center text-white ">
                <h4>{{ onCheck(fields.title) }}</h4>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol col="12">
                <CSelect :label="onCheck(fields.schoolName)"
                         :disabled="true"
                         :horizontal="{label: 'col-sm-3', input: 'col-sm-9'}"
                         @update:value="onSelectCampus"
                         :options="onCheck(campus)"/>
              </CCol>
              <CCol col="12">
                <CSelect :label="onCheck(fields.facultys)"
                         :disabled="disable"
                         :horizontal="{label: 'col-sm-3', input: 'col-sm-9'}"
                         @update:value="onSelectFacultys"
                         :options="onCheck(facultys)"/>
              </CCol>
              <CCol col="12">
                <CSelect :label="this.onCheck(fields.departments)"
                         :disabled="disable"
                         :horizontal="{label: 'col-sm-3', input: 'col-sm-9'}"
                         @update:value="onSelectDepartments"
                         :options="onCheck(departments)"/>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CRow>
      <CCol>
        <CTabs variant="pills" :active-tab.sync="activeTab" fill>
          <CTab active>
            <template slot="title">
              <CIcon name="cil-clipboard"/>
              {{ onCheck(fields.tqf3) }}
            </template>
            <CCard>
              <CCardBody>

                <CListGroup>
                  <CListGroupItem color="primary"><CIcon name="cil-school"/> {{onCheck(title.section)}} 1. {{ onCheck(title.general) }} </CListGroupItem>
                  <CCardBody class="p-3 border-light">
                    <generallnfimation-sub/>
                  </CCardBody>

                  <CListGroupItem color="primary"><CIcon name="cil-school"/> {{onCheck(title.section)}} 2. {{ onCheck(title.courseSyllabus) }} </CListGroupItem>
                  <CCardBody class="p-3 border-light">
                    <course-syllabus/>
                  </CCardBody>

                  <CListGroupItem color="primary"><CIcon name="cil-school"/> {{onCheck(title.section)}} 3. {{ onCheck(title.development) }} </CListGroupItem>
                  <CCardBody class="p-3 border-light">
                    <development/>
                  </CCardBody>

                  <CListGroupItem color="primary"><CIcon name="cil-school"/> {{onCheck(title.section)}} 4. {{ onCheck(title.lesson) }} </CListGroupItem>
                  <CCardBody class="p-3 border-light">
                    <lesson-plan/>
                  </CCardBody>

                  <CListGroupItem color="primary"><CIcon name="cil-school"/> {{onCheck(title.section)}} 5. {{ onCheck(title.references) }} </CListGroupItem>
                  <CCardBody class="p-3 border-light">
                    <references/>
                  </CCardBody>

                  <CListGroupItem color="primary"><CIcon name="cil-school"/> {{onCheck(title.section)}} 6. {{ onCheck(title.evaluation) }} </CListGroupItem>
                  <CCardBody class="p-3 border-light">
                    <evaluation-and-improvement/>
                  </CCardBody>
                </CListGroup>

              </CCardBody>
            </CCard>
          </CTab>

          <CTab>
            <template slot="title">
              <CIcon name="cil-clipboard"/>
              {{ onCheck(fields.tqf4) }}
            </template>

            <CCard>
              <CCardBody>
                <CListGroup>
                  <CListGroupItem color="primary"><CIcon name="cil-school"/> {{onCheck(title.section)}} 1. {{ onCheck(title.general) }} </CListGroupItem>
                  <CCardBody class="p-3 border-light">
                    <generallnfimation/>
                  </CCardBody>

                  <CListGroupItem color="primary"><CIcon name="cil-school"/> {{onCheck(title.section)}} 2. {{ onCheck(title.courseSyllabus) }} </CListGroupItem>
                  <CCardBody class="p-3 border-light">
                    <course-syllabus tqf="4"/>
                  </CCardBody>

                  <CListGroupItem color="primary"><CIcon name="cil-school"/> {{onCheck(title.section)}} 3. {{ onCheck(title.development) }} </CListGroupItem>
                  <CCardBody class="p-3 border-light">
                    <development tqf="4"/>
                  </CCardBody>

                  <CListGroupItem color="primary"><CIcon name="cil-school"/> {{onCheck(title.section)}} 4. {{ onCheck(title.lesson) }} </CListGroupItem>
                  <CCardBody class="p-3 border-light">
                    <plannings/>
                  </CCardBody>

                  <CListGroupItem color="primary"><CIcon name="cil-school"/> {{onCheck(title.section)}} 5. {{ onCheck(title.evaluations) }} </CListGroupItem>
                  <CCardBody class="p-3 border-light">
                    <evaluation-plan/>
                  </CCardBody>

                  <CListGroupItem color="primary"><CIcon name="cil-school"/> {{onCheck(title.section)}} 6. {{ onCheck(title.evaluation) }} </CListGroupItem>
                  <CCardBody class="p-3 border-light">
                    <evaluation-and-improvement4/>
                  </CCardBody>
                </CListGroup>
              </CCardBody>
            </CCard>
          </CTab>

          <CTab>
            <template slot="title">
              <CIcon name="cil-clipboard"/>
              {{ onCheck(fields.tqf5) }}
            </template>
            <CCard>
              <CListGroup>

                <CListGroupItem color="primary"><CIcon name="cil-school"/> {{onCheck(title.section)}} 1. {{ onCheck(title.general) }} </CListGroupItem>
                <CCardBody class="p-3 border-light">
                  <generallnfimation/>
                </CCardBody>


                <CListGroupItem color="primary"><CIcon name="cil-library"/> {{onCheck(title.section)}} 3. {{ onCheck(title.summary) }} </CListGroupItem>
                <CCardBody class="p-3 border-light">
                  <experience/>
                </CCardBody>

                <CListGroupItem color="primary"><CIcon name="cil-library"/> {{onCheck(title.section)}} 4. {{ onCheck(title.improvement) }} </CListGroupItem>
                <CCardBody class="p-3 border-light">
                  <imporvement-plans3/>
                </CCardBody>
              </CListGroup>
            </CCard>
          </CTab>

          <CTab>
            <template slot="title">
              <CIcon name="cil-clipboard"/>
              {{ onCheck(fields.tqf6) }}
            </template>
            <CCard>
              <CListGroup>
                <CListGroupItem color="primary"><CIcon name="cil-school"/> {{onCheck(title.section)}} 1. {{ onCheck(title.general) }} </CListGroupItem>
                <CCardBody class="p-3 border-light">
                  <generallnfimation/>
                </CCardBody>

                <CListGroupItem color="primary"><CIcon name="cil-sitemap"/> {{onCheck(title.section)}} 2. {{ onCheck(title.courseBetween) }} </CListGroupItem>
                <CCardBody class="p-3 border-light">
                  <consistency/>
                </CCardBody>

                <CListGroupItem color="primary"><CIcon name="cil-library"/> {{onCheck(title.section)}} 3. {{ onCheck(title.summary) }} </CListGroupItem>
                <CCardBody class="p-3 border-light">
                  <experience/>
                </CCardBody>


                <CListGroupItem color="primary"><CIcon name="cil-library"/> {{onCheck(title.section)}} 4. {{ onCheck(title.improvement) }} </CListGroupItem>
                <CCardBody class="p-3 border-light">
                  <imporvement-plans/>
                </CCardBody>

              </CListGroup>
            </CCard>
          </CTab>
        </CTabs>
      </CCol>
    </CRow>
  </div>
</template>

<script>

import {mapGetters} from 'vuex'
import Generallnfimation from "@/project/views/pages/sub/GeneralInfomation";
import CourseSyllabus from "@/project/views/pages/sub/CourseSyllabus";
import Development from "@/project/views/pages/sub/Development";
import LessonPlan from "@/project/views/pages/sub/LessonPlan";
import References from "@/project/views/pages/sub/References";
import EvaluationAndImprovement from "@/project/views/pages/sub/EvaluationAndImprovement";
import Experience from "@/project/views/pages/sub/Experience";
import ImporvementPlans from "@/project/views/pages/sub/ImporvementPlans";
import DialogInstructors from "@/project/views/pages/dialog/DialogInstructors";
import DialogLessonPlans from "@/project/views/pages/dialog/DialogLessonPlans";
import DialogEvaluationPlans from "@/project/views/pages/dialog/DialogEvaluationPlans";
import Consistency from "@/project/views/pages/sub/Consistency";
import ImporvementPlans3 from "@/project/views/pages/sub/ImporvementPlans3";
import Plannings from "@/project/views/pages/sub/Plannings";
import EvaluationPlan from "@/project/views/pages/sub/EvaluationPlan";
import EvaluationAndImprovement4 from "@/project/views/pages/sub/EvaluationAndImprovement4";
import DialogDevelopPlans from "@/project/views/pages/dialog/DialogDevelopPlans";
import DialogConsistency from "@/project/views/pages/dialog/DialogConsistency";
import GenerallnfimationSub from "@/project/views/pages/subs/GeneralInfomation";

export default {
  name: 'TQFDetail',
  components: {
    GenerallnfimationSub,
    DialogConsistency,
    DialogDevelopPlans,
    EvaluationAndImprovement4,
    EvaluationPlan,
    Plannings,
    ImporvementPlans3,
    Consistency,
    DialogEvaluationPlans,
    DialogLessonPlans,
    DialogInstructors,
    ImporvementPlans,
    Experience,
    EvaluationAndImprovement, References, LessonPlan, Development, CourseSyllabus, Generallnfimation},
  data: function () {
    return {

      activeTab: 1,
      title : {
        section: [
          {
            key: "th",
            value: "หมวดที่ "
          },
          {
            key: "en",
            value: "Section "
          }
        ],
        general: [
          {
            key: "th",
            value: "ข้อมูลทั่วไป"
          },
          {
            key: "en",
            value: "General Infomation"
          }
        ],
        courseSyllabus: [
          {
            key: "th",
            value: "ลักษณะและการดำเนินการ"
          },
          {
            key: "en",
            value: "Course Syllabus"
          }
        ],
        development: [
          {
            key: "th",
            value: "การพัฒนาผลการเรียนรู้ของนักศึกษา"
          },
          {
            key: "en",
            value: "Development of Students Learning Outcomes"
          }
        ],
        lesson: [
          {
            key: "th",
            value: "การพัฒนาผลการเรียนรู้ของนักศึกษา"
          },
          {
            key: "en",
            value: "Lesson Plan and Evaluation"
          }
        ],
        references: [
          {
            key: "th",
            value: "เอกสารอ้างอิง"
          },
          {
            key: "en",
            value: "References"
          }
        ],
        evaluations: [
          {
            key: "th",
            value: "การประเมิน"
          },
          {
            key: "en",
            value: "Evaluation"
          }
        ],
        evaluation: [
          {
            key: "th",
            value: "การประเมินและปรับปรุงการดำเนินการของวิชา"
          },
          {
            key: "en",
            value: "Evaluation and Improvement of Course Operations"
          }
        ],
        courseBetween: [
          {
            key: "th",
            value: "การดำเนินการที่เปรียบเทียบกับแผนการเตรียมการ"
          },
          {
            key: "en",
            value: "Course Between Lesson Plans And Actual Lectures"
          }
        ],
        summary: [
          {
            key: "th",
            value: "ผลการดำเนินการ"
          },
          {
            key: "en",
            value: "Summary of Field Experience Implementation"
          }
        ],
        improvement: [
          {
            key: "th",
            value: "ผลการดำเนินการ"
          },
          {
            key: "en",
            value: "Summary of Field Experience Implementation"
          }
        ],

      },
      fields: {
        title: [
          {
            key: "th",
            value: "รายละเอียดของรายวิชา (มศอ)"
          },
          {
            key: "en",
            value: "Course Spceification TQF"
          }
        ],
        schoolName: [
          {
            key: "th",
            value: "ชื่อสถาบันอุดมศึกษา"
          },
          {
            key: "en",
            value: "Name of University"
          }
        ],
        facultys: [
          {
            key: "th",
            value: "สำนักวิชา"
          },
          {
            key: "en",
            value: "School"
          }
        ],
        departments: [
          {
            key: "th",
            value: "สาขาวิชา"
          },
          {
            key: "en",
            value: "Program"
          }
        ],

        tqf3: [
          {
            key: "th",
            value: "รายละเอียดของรายวิชา (มศอ 3)"
          },
          {
            key: "en",
            value: "Course Spceification TQF 3"
          }
        ],
        tqf4: [
          {
            key: "th",
            value: "รายละเอียดของรายวิชา (มศอ 4)"
          },
          {
            key: "en",
            value: "Course Spceification TQF 4"
          }
        ],
        tqf5: [
          {
            key: "th",
            value: "รายละเอียดของรายวิชา (มศอ 5)"
          },
          {
            key: "en",
            value: "Course Spceification TQF 5"
          }
        ],
        tqf6: [
          {
            key: "th",
            value: "รายละเอียดของรายวิชา (มศอ 6)"
          },
          {
            key: "en",
            value: "Course Spceification TQF 6"
          }
        ],

      },
      data: {

      }
    }
  },
  mounted() {

  },

  created() {
    this.$store.dispatch("course/onGetCampus")
  },

  beforeDestroy() {

  },

  methods: {

    onCheck(key) {
      for (var i = 0; i < key.length; i++) {
        if (key[i].key == this.lang) {
          return key[i].value;
        }
      }
    },

    //

    onSelectCampus(value, e) {
      this.data.campus = value;
      this.$store.dispatch("course/onGetFacultys", this.data.campus._id)

      var obj = {};
      obj.campus = this.data.campus._id

      this.$store.dispatch("course/onGetOperations",obj)
      this.$store.dispatch("course/onGetVerifications",obj)

    },

    onSelectFacultys(value, e) {
      this.data.facultys = value;
      this.$store.dispatch("course/onGetDepartment", this.data.facultys._id)
    },

    onSelectDepartments(value, e) {
      this.data.department = value;
      this.onLoadProgram()

    },


    onLoadProgram() {
      var body = {};
      body.campusId = this.data.campus._id
      body.facultyId = this.data.facultys._id
      body.departmentId = this.data.department._id

      this.$store.dispatch("course/onGetPrograms", body)
      this.$store.dispatch("course/onGetCategorys", body)
    }
  },

  computed: {
    ...mapGetters({

      lang: "setting/objLang",

      campus: "course/objCampus",
      facultys: "course/objFacultys",
      departments: "course/objDepartments",


      courses: "course/objCourses",
      program: "course/objProgram",
      type: "course/objType",

    })
  },

  watch: {
    campus(value) {
      this.data.campus = this.onCheck(value)[0].value
      this.$store.dispatch("course/onGetFacultys", this.data.campus._id)
      this.$store.dispatch("course/onGetCourses", this.data.campus._id)


      var obj = {};
      obj.campus = this.data.campus._id

      this.$store.dispatch("course/onGetOperations",obj)
      this.$store.dispatch("course/onGetVerifications",obj)
    },

    facultys(value) {
      this.data.facultys = this.onCheck(value)[0].value
      this.$store.dispatch("course/onGetDepartment", this.data.facultys._id)
    },

    departments(value) {
      this.data.department = this.onCheck(value)[0].value
      this.onLoadProgram()

    }
  }
}
</script>
