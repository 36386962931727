<template>
  <div>
    <CRow class="mb-3">
      <CCol col="12">
        <p class="mt-2  font-weight-bold">{{ this.onCheck(this.fields.evaluation) }} </p>
        <p class="mb-3 font-weight-bold">1. {{ this.onCheck(this.fields.assessment)}}</p>
        <CMultiSelect
          v-if="this.data.assessment.length > 0"
          @update="oncheckAssessment"
          :options="this.data.assessment"
          :search="false"
          :selected="[]"
          :selection="false"
          inline
          optionsEmptyPlaceholder="No options placeholder"
          searchPlaceholder="Search"
          selectionType="tags"
        >
        </CMultiSelect>
      </CCol>
    </CRow>

    <CRow class="mb-3">
      <CCol col="12">
        <p class="mt-2  font-weight-bold">2. {{ this.onCheck(this.fields.experienceAdvisors) }} </p>
        <CMultiSelect
          v-if="this.data.assessment.length > 0"
          @update="oncheckAssessment"
          :options="this.data.assessment"
          :search="false"
          :selected="[]"
          :selection="false"
          inline
          optionsEmptyPlaceholder="No options placeholder"
          searchPlaceholder="Search"
          selectionType="tags"
        >
        </CMultiSelect>
      </CCol>
    </CRow>

    <CRow class="mb-3">
      <CCol col="12">
        <p class="mt-2  font-weight-bold">3. {{ this.onCheck(this.fields.courseCoordinator) }} </p>
        <CMultiSelect
          v-if="this.data.assessment.length > 0"
          @update="oncheckAssessment"
          :options="this.data.assessment"
          :search="false"
          :selected="[]"
          :selection="false"
          inline
          optionsEmptyPlaceholder="No options placeholder"
          searchPlaceholder="Search"
          selectionType="tags"
        >
        </CMultiSelect>
      </CCol>
    </CRow>



    <CRow class="mb-3">
      <CCol col="12">
        <p class="mb-2 font-weight-bold">4. {{ this.onCheck(this.fields.verification)}}</p>
        <p class="mb-3 ">{{ this.onCheck(this.fields.verificationDetail)}}</p>
        <CMultiSelect
          v-if="this.data.verification.length > 0"
          @update="oncheckVerification"
          :options="this.data.verification"
          :search="false"
          :selected="[]"
          :selection="false"
          inline
          optionsEmptyPlaceholder="No options placeholder"
          searchPlaceholder="Search"
          selectionType="tags"
        >
        </CMultiSelect>
      </CCol>
    </CRow>

    <CRow>
      <CCol col="12">
        <p class="mb-2 font-weight-bold">{{ this.onCheck(this.fields.instructor)}}</p>

      </CCol>
    </CRow>

  </div>
</template>

<script>

import {mapGetters} from 'vuex'

export default {
  name: 'EvaluationAndImprovement4',
  props: {
    disable: {
      type: Boolean,
      default: false
    },
  },
  data: function () {
    return {
      status:false,
      fields:{
        evaluation: [
          {
            key: "th",
            value: "**สามารถระบุได้มากกว่า 1 ข้อ **"
          },
          {
            key: "en",
            value: "Please give more than one, if possible."
          }
        ],
        assessment:[
          {
            key: "th",
            value: "กลยุทธ์การประเมินประสิทธิผลของการฝึกประสบการณ์ภาคสนามโดยนักศึกษา"
          },
          {
            key: "en",
            value: "Assessment Strategy by Students"
          }
        ],
        experienceAdvisors:[
          {
            key: "th",
            value: "กลยุทธ์การประเมินการฝึกประสบการณ์ภาคสนามของพนักงานพี่เลี้ยง/อาจารย์พี่เลี้ยง/อาจารย์ที่ปรึกษา"
          },
          {
            key: "en",
            value: "  Assessment Strategy by Internship Mentors/ Field Experience Advisors"
          }
        ],

        courseCoordinator:[
          {
            key: "th",
            value: "กลยุทธ์การประเมินการฝึกประสบการณ์ภาคสนามของอาจารย์นิเทศ/อาจารย์ผู้ประสานงานรายวิชา/อาจารย์ผู้รับผิดชอบรายวิชา"
          },
          {
            key: "en",
            value: "Assessment Strategy by Field Experience Monitors/ Course Coordinator"
          }
        ],



        verification:[
          {
            key: "th",
            value: "กระบวนการทวนสอบผลสัมฤทธิ์การเรียนรู้/ การทวนสอบการประเมินผลการเรียนรู้"
          },
          {
            key: "en",
            value: "Verification of Learning Outcomes"
          }
        ],
        verificationDetail:[
          {
            key: "th",
            value: "(ทวนสอบในรูปแบบ Constructive alignment โดยพิจารณาแต่ละผลลัพธ์การเรียนรู้ระดับรายวิชา (Course Learning Outcome) สอนอย่างไร ประเมินอย่างไร สัดส่วนการประเมิน การกระจายเกรด การออกข้อสอบ         ที่สอดคล้องกับ ผลลัพธ์การเรียนรู้ระดับรายวิชาที่กำหนด ตลอดจนประเด็นปัญหาต่าง ๆ ที่อาจพบในกระบวน การเรียนการสอน) "
          },
          {
            key: "en",
            value: "To verify the constructive alignment model, it needs to consider Course Learning Outcome (CLOs) in terms of teaching method, assessment details (method and proportion, grading) and other teaching and learning problems. \n"
          }
        ],
        instructor: [
          {
            key: "th",
            value: "ชื่ออาจารย์ผู้สอน หรืออาจารย์ผู้ประสานงานรายวิชา / อาจารย์ผู้รับผิดชอบรายวิชา"
          },
          {
            key: "en",
            value: "Name of Course Instructor / Course Coordinator "
          }
        ],

      },
      data:{
        assessment :[],
        verification :[],
        instructor:[]

      }
    }
  },
  mounted() {

  },

  created() {

  },

  beforeDestroy() {

  },

  methods: {
    onCheck(key) {
      for (var i = 0; i < key.length; i++) {
        if (key[i].key == this.lang) {
          return key[i].value;
        } else {
          if (key[i].key == "ch") {
            return key[i].value;
          }
        }
      }
    },

    oncheckAssessment(item){
      console.log(item)
    },
    oncheckVerification(item){
      console.log(item)
    },

  },


  computed: {
    ...mapGetters({
      lang: "setting/objLang",
      assessment : "course/objOperations",
      verification : "course/objVerification",

    })
  },

  watch: {
    assessment(value){
      console.log("assessment",value)
      this.data.assessment = this.onCheck(value)
    },
    verification(value){
      this.data.verification = this.onCheck(value)


    },

  }
}
</script>
