<template>
  <CCard accent-color="secondary">
    <CCardHeader v-if="!icon">
      <slot name="header" >
        <CIcon v-if="icon" :name="icon"/> <span class="font-weight-bold">{{caption}}</span>
        <div class="card-header-actions">
          <CButton size="sm" color="success" shape="pill" variant="outline"> <CIcon name="cil-library-add"/> </CButton>
        </div>
      </slot>
    </CCardHeader>
    <CCardBody class="p-0">
      <CDataTable
        :hover="hover"
        :striped="striped"
        :bordered="bordered"
        :small="small"
        :fixed="fixed"
        :items="items"
        :fields="fields"
        :items-per-page="small ? 20 : 10"
        :dark="dark"
        pagination
      >
        //


        <template #actions="{index,item}">
          <td>
            <div class="text-center">{{item.actions}}</div>
          </td>
        </template>


        <template ##="{item}">
          <td>
            <CRow>
              <CCol class="text-center">
                <CButton size="sm" color="info" shape="pill" variant="outline" @click="onRemove(item)" >
                  <CIcon name="cil-clipboard"/>
                </CButton>
              </CCol>
            </CRow>
          </td>
        </template>
      </CDataTable>
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  name: 'TableConsistency',
  props: {
    items: Array,
    fields: {
      type: Array,
    },
    icon: {
      type: String,
      default: 'cil-user'
    },
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    bordered: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  methods: {
    getBadge (status) {
      return status === 'Active' ? 'success'
        : status === 'Inactive' ? 'secondary'
          : status === 'Pending' ? 'warning'
            : status === 'Banned' ? 'danger' : 'primary'
    },
    onchangeInstructor(item){
      for(var i=0; i<this.items.length; i++){
        if(this.items[i].userId == item.userId){
          this.items[i].instructor = !item.instructor
        }
      }
      this.$emit("instructor",item)
    },
    onchangeCodication(item){
      for(var i=0; i<this.items.length; i++){
        if(this.items[i].userId == item.userId){
          this.items[i].codication = !item.codication
        }
      }
      this.$emit("codication",item)
    },
    onRemove(item){
      this.items = this.items.filter(function(obj) {return obj != item;})
      this.$emit("removeInstructor",item)
    },

    onAddInstructors(item){
      this.$emit("addInstructor",item)
    }
  }
}
</script>
