<template>
  <div>

    <CRow>
      <CCol>
        <span class="font-weight-bold"> {{ onCheck(fields.plans) }} </span>
      </CCol>
    </CRow>

    <CRow class="pt-2">
      <CCol>
        <table-grad
          small
          :items="data.plans"
          :fields="onCheck(table.plans)"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol col="12" class="text-right">
        <CButton size="sm" color="success" shape="pill" variant="outline" @click="addVerlification" >
          <CIcon name="cil-plus"/>
        </CButton>
      </CCol>
    </CRow>


    <CRow>
      <CCol>
        <span class="font-weight-bold"> {{ onCheck(fields.teaching) }} </span>
      </CCol>
    </CRow>

    <CRow class="mt-2 mb-2">
      <CCol>
        <quill-editor :content="onCheck(data.teaching)" @change="chaneTeaching" />
      </CCol>
    </CRow>

    <CRow>
      <CCol>
        <span class="font-weight-bold"> {{ onCheck(fields.program) }} </span>
      </CCol>
    </CRow>

    <CRow class="mt-2 mb-2">
      <CCol>
        <quill-editor :content="onCheck(data.program)" @change="chaneProgram" />
      </CCol>
    </CRow>






  </div>
</template>

<script>

import {mapGetters} from 'vuex'
import TableGrad from "@/project/containers/sub/TableGrade";

import Vue from 'vue'
import VueQuillEditor from 'vue-quill-editor'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

Vue.use(VueQuillEditor)

export default {
  name: 'ImporvementPlans3',
  components: {TableGrad},
  props: {
    disable: {
      type: Boolean,
      default: false
    },
  },
  data: function () {
    return {
      table: {
        plans: [{
          key: "th",
          value: [
            {key: 'courseDevelopment', label: 'แผนการพัฒนา / ปรับปรุงรายวิชา',_style:'text-align: center'},
            {key: 'summary', label: 'ผลการดำเนินการ',_style:'text-align: center'},
            {key: '#', label: '#',_style:'text-align: center'},
          ]
        }, {
          key: "en",
          value: [
            {key: 'courseDevelopment', label: 'Course Development / Improvement Plans',_style:'text-align: center'},
            {key: 'summary', label: 'Summary of implementation',_style:'text-align: center'},
            {key: '#', label: '#',_style:'text-align: center'},
          ]
        }],

      },
      fields : {
        plans: [{
          key: "th",
          value: "1. ผลการดำเนินงาน การพัฒนา / ปรับปรุงรายวิชาตามที่ระบุไว้ใน มคอ. 3 หมาวดที่ 2 ข้อที่ 3 "
        }, {
          key: "en",
          value: "1. Summary of Course Development Improvement Implement Implementation as Specified in TQF 3 Section 2 No.3"
        }],
        teaching: [{
          key: "th",
          value: "2. จำนวนนักศึกษาที่ลงทะเบียน / ส่งไปฝึกประสบการณ์ภาคสนาม"
        }, {
          key: "en",
          value: "2. Specify reading materials including textbooks, docments, suggested reading and or other resources (with standard referencing format)"
        }],
        program: [{
          key: "th",
          value: "3. ข้อเสนอแนะต่ออาจารย์ผู้รับผิดชอบหลักสูตร"
        }, {
          key: "en",
          value: "3. Suggestion for Program Chairperson"
        }],

      },
      data: {
        plans:[],
        teaching:'',
        program:''
      }

    }
  },
  mounted() {

  },

  created() {
    // this.content = this.onCheck(this.values.fields)
  },

  beforeDestroy() {

  },

  methods: {

    onCheck(key) {
      for (var i = 0; i < key.length; i++) {
        if (key[i].key == this.lang) {
          return key[i].value;
        }
      }
    },

    chaneTeaching(html, text, quill) {
      // this.onCheckIndex(this.data.reason,html.html)
    },

    chaneProgram(html, text, quill) {
      // this.onCheckIndex(this.data.reason,html.html)
    },


  },



  computed: {
    ...mapGetters({
      lang: "setting/objLang",
    })
  },

  watch: {
    // Watch content change

  }
}
</script>
