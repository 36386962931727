var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCard',{attrs:{"accent-color":"secondary"}},[(!_vm.icon)?_c('CCardHeader',[_vm._t("header",[(_vm.icon)?_c('CIcon',{attrs:{"name":_vm.icon}}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.caption))]),_c('div',{staticClass:"card-header-actions"},[_c('CButton',{attrs:{"size":"sm","color":"success","shape":"pill","variant":"outline"}},[_c('CIcon',{attrs:{"name":"cil-library-add"}})],1)],1)])],2):_vm._e(),_c('CCardBody',{staticClass:"p-0"},[_c('CDataTable',{attrs:{"hover":_vm.hover,"striped":_vm.striped,"bordered":_vm.bordered,"small":_vm.small,"fixed":_vm.fixed,"items":_vm.items,"fields":_vm.fields,"items-per-page":_vm.small ? 20 : 10,"dark":_vm.dark,"pagination":""},scopedSlots:_vm._u([{key:"no",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_c('td',[_c('div',{staticClass:"text-center"},[_vm._v(_vm._s(index+1))])])]}},{key:"grade",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_c('td',[_c('div',{staticClass:"text-center"},[_vm._v(_vm._s(item.grade))])])]}},{key:"rang",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_c('td',[_c('div',{staticClass:"text-center"},[_vm._v(_vm._s(item.rang))])])]}},{key:"frequency",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_c('td',[_c('div',{staticClass:"text-center"},[_vm._v(_vm._s(item.frequency))])])]}},{key:"percentage",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_c('td',[_c('div',{staticClass:"text-center"},[_vm._v(_vm._s(item.percentage))])])]}},{key:"#",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CRow',[_c('CCol',{staticClass:"text-center"},[_c('CButton',{attrs:{"size":"sm","color":"info","shape":"pill","variant":"outline"},on:{"click":function($event){return _vm.onRemove(item)}}},[_c('CIcon',{attrs:{"name":"cil-clipboard"}})],1)],1)],1)],1)]}}])},[_vm._v(" // ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }