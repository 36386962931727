<template>
  <div>
    <CRow>
      <CCol>
        <CInput type="number" :horizontal="{label: 'col-sm-5 font-weight-bold', input: 'col-sm-7'}"
                :label="onCheck(fields.enroll)"/>
      </CCol>
    </CRow>

    <CRow>
      <CCol>
        <CInput type="number" :horizontal="{label: 'col-sm-5 font-weight-bold', input: 'col-sm-7'}"
                :label="onCheck(fields.seneter)"/>
      </CCol>
    </CRow>

    <CRow>
      <CCol>
        <span class="font-weight-bold"> {{ onCheck(fields.gradeDistribution) }} </span>
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <span class="pl-4 pt-2"> {{ onCheck(fields.details) }} </span>
      </CCol>
    </CRow>
    <CRow class="mt-2">
      <CCol>
        <table-grad
          small
          :items="data.grade"
          :fields="onCheck(table.grade)"
        />
      </CCol>

    </CRow>

    <CRow>
      <CCol>
        <span class="font-weight-bold"> {{ onCheck(fields.assessment) }} </span>
      </CCol>
    </CRow>

    <CRow class="p-2">
      <CCol>
        <span > {{ onCheck(fields.averageGrade) }} </span>
      </CCol>
    </CRow>

    <CRow class="p-2">
      <CCol>
        <span > {{ onCheck(fields.suggestion) }} </span>
      </CCol>
    </CRow>

    <CRow>
      <CCol>
        <quill-editor :content="onCheck(data.suggestion)" @change="chaneSuggestion"  />
      </CCol>
    </CRow>

    <CRow class="p-2">
      <CCol>
        <span > {{ onCheck(fields.lecturer) }} </span>
      </CCol>
    </CRow>

    <CRow>
      <CCol>
        <quill-editor :content="onCheck(data.lecturer)" @change="chaneLecturer" />
      </CCol>
    </CRow>

    <CRow class="mt-2">
      <CCol>
        <span class="font-weight-bold"> {{ onCheck(fields.verlification) }} </span>
      </CCol>
    </CRow>

    <CRow class="p-2 ">
      <CCol>
        <span > {{ onCheck(fields.subVerlification) }} </span>
      </CCol>
    </CRow>

    <CRow>
      <CCol>
        <table-grad
          small
          :items="data.verlification"
          :fields="onCheck(table.verlification)"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol col="12" class="text-right">
        <CButton size="sm" color="success" shape="pill" variant="outline" @click="addVerlification" >
          <CIcon name="cil-plus"/>
        </CButton>
      </CCol>
    </CRow>

    <CRow class="mt-2">
      <CCol>
        <span class="font-weight-bold"> {{ onCheck(fields.noteVerlification) }} </span>
      </CCol>
    </CRow>

  </div>
</template>

<script>

import {mapGetters} from 'vuex'
import TableGrad from "@/project/containers/sub/TableGrade";

import Vue from 'vue'
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

Vue.use(VueQuillEditor)

export default {
  name: 'Experience',
  components: {TableGrad},
  props: {
    disable: {
      type: Boolean,
      default: false
    },
  },
  data: function () {
    return {
      table: {
        grade: [{
          key: "th",
          value: [
            {key: 'grade', label: 'ค่าระดับคะแนน',_style:'text-align: center'},
            {key: 'rang', label: 'ช่วงคะแนน',_style:'text-align: center'},
            {key: 'frequency', label: 'จำนวน',_style:'text-align: center'},
            {key: 'percentage', label: 'ร้อยละ',_style:'text-align: center'},
          ]
        }, {
          key: "en",
          value: [
            {key: 'grade', label: 'Grade',_style:'text-align: center'},
            {key: 'rang', label: 'ช่วงคะแนน',_style:'text-align: center'},
            {key: 'frequency', label: 'Frequency',_style:'text-align: center'},
            {key: 'percentage', label: 'Percentage',_style:'text-align: center'},
          ]
        }],
        verlification: [{
          key: "th",
          value: [
            {key: 'method', label: 'วิธีการทวนสอบ',_style:'text-align: center'},
            {key: 'conclusion', label: 'สรุปผล',_style:'text-align: center'},
            {key: '#', label: '#',_style:'text-align: center'},
          ]
        }, {
          key: "en",
          value: [
            {key: 'method', label: 'Verification of Method',_style:'text-align: center'},
            {key: 'conclusion', label: 'Conclusion',_style:'text-align: center'},
            {key: '#', label: '#',_style:'text-align: center'},
          ]
        }]
      },
      fields : {
        enroll: [{
          key: "th",
          value: "1. จำนวนนักศึกษาที่ลงทะเบียน / ส่งไปฝึกประสบการณ์ภาคสนาม"
        }, {
          key: "en",
          value: "1. Specify reading materials including textbooks, docments, suggested reading and or other resources (with standard referencing format)"
        }],
        seneter: [{
          key: "th",
          value: "2. จำนวนนักศึกษาที่คงอยู่เมื่อสิ้นสุดการฝึกประสบการณ์ภาคสนาม"
        }, {
          key: "en",
          value: "2. Number of students retained at the end of the semester"
        }],
        gradeDistribution: [{
          key: "th",
          value: "3. จำนวนนักศึกษาที่คงอยู่เมื่อสิ้นสุดการฝึกประสบการณ์ภาคสนาม"
        }, {
          key: "en",
          value: "3. Grade Distribution"
        }],
        details: [{
          key: "th",
          value: "ระบุจำนวนแล้วร้อยละของนักศึกษาในแต่ละระดับคะแนน"
        }, {
          key: "en",
          value: "Please specify number and percentage of students in each grade group"
        }],

        // 4
        assessment: [{
          key: "th",
          value: "4. ผลการประเมินรายวิชาโดยนักศึกษา"
        }, {
          key: "en",
          value: "4. Assessment Strategy by Students"
        }],
        averageGrade: [
          {
            key: "th",
            value: "4.1 คะแนนประเมินเฉลี่ย"
          },
          {
            key: "en",
            value: "4.1 Average Grade"
          }
        ],
        suggestion: [
          {
            key: "th",
            value: "4.2 ข้อเสนอแนะที่เป็นประโยชน์ต่อการจัดการเรียนการสอน"
          },
          {
            key: "en",
            value: "4.2 Suggestion for Teaching and Learning"
          }
        ],
        lecturer: [
          {
            key: "th",
            value: "4.3 ความเห็นของอาจารย์ต่อข้อเสนอแนะ"
          },
          {
            key: "en",
            value: "4.3 Lecturer's Suggestion "
          }
        ],

        //5
        verlification: [
          {
            key: "th",
            value: "5. สรุปการทวนสอบผลการประเมินการเรียนรู้"
          },
          {
            key: "en",
            value: "5. Verification of Learning Outcode "
          }
        ],

        subVerlification: [
          {
            key: "th",
            value: "ระบุวิธีการทวนสอบ และสรุปผลทวนสอบ ซึ่งประกอบด้วยข้อคิดเห็นของคณะกรรมการทวนสอบ และ / อาจารย์ผู้สอน / อาจารย์พี่เลี้ยง / อาจาย์ที่ปรึกษา"
          },
          {
            key: "en",
            value: "Specify the verification of method and conclusion which the suggestion of the verification committee / course instructor"
          }
        ],

        noteVerlification: [
          {
            key: "th",
            value: "หมายเหตุ: มีการทวนสอบผลการประเมินการเรียนรู้ ทุกรายวิชาที่เปิดสอนในแต่ละภาคการศึกษา"
          },
          {
            key: "en",
            value: "Remark: The student learning outcome of all courses will be verified every semester"
          }
        ],


      },
      data: {
        grade:[
          {
            grade:"A",
            rang:"100-80",
            frequency:"10",
            percentage:"80"
          },
          {
            grade:"B+",
            rang:"",
            frequency:"",
            percentage:""
          },
          {
            grade:"B",
            rang:"",
            frequency:"",
            percentage:""
          },
          {
            grade:"C+",
            rang:"",
            frequency:"",
            percentage:""
          },
          {
            grade:"C",
            rang:"",
            frequency:"",
            percentage:""
          },
          {
            grade:"D+",
            rang:"",
            frequency:"",
            percentage:""
          },
          {
            grade:"D",
            rang:"",
            frequency:"",
            percentage:""
          },
          {
            grade:"F",
            rang:"",
            frequency:"",
            percentage:""
          },
          {
            grade:"I",
            rang:"",
            frequency:"",
            percentage:""
          },
          {
            grade:"S",
            rang:"",
            frequency:"",
            percentage:""
          },
          {
            grade:"U",
            rang:"",
            frequency:"",
            percentage:""
          },
          {
            grade:"รวม",
            rang:"",
            frequency:"",
            percentage:""
          }
        ],
        suggestion:'',
        lecturer:'',
        verlification:[]
      }

    }
  },
  mounted() {

  },

  created() {
    // this.content = this.onCheck(this.values.fields)
  },

  beforeDestroy() {

  },

  methods: {

    onCheck(key) {
      for (var i = 0; i < key.length; i++) {
        if (key[i].key == this.lang) {
          return key[i].value;
        }
      }
    },

    chaneSuggestion(html, text, quill) {
      // this.onCheckIndex(this.data.reason,html.html)
    },

    chaneLecturer(html, text, quill) {
      // this.onCheckIndex(this.data.reason,html.html)
    },

    addVerlification(){

    }

  },



  computed: {
    ...mapGetters({
      lang: "setting/objLang",
    })
  },

  watch: {
    // Watch content change

  }
}
</script>
